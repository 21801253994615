import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react'
import SelectType from '../categorised-search/selectType';
import FormFields from '../form-fields';

const TagSelector = ({ id, label, type = "multiselect", onChange, options = [], defaultValue, value, className, highlighted, placeholder, searchInput, hideEmpty = true, ...restProps }) => {
    const isMounted = useRef(false);
    const multiple = useMemo(() => type === "multiselect", [type])
    const [selectedTags, setSelectedTags] = useState(multiple ? [] : null);

    const filterData = useCallback((data) => {
        // return data;
        return data.filter(item => item.displayName.toLowerCase().includes(searchInput.toLowerCase()));
    }, [searchInput]);

    const filteredOptions = useMemo(() => filterData(options), [options, filterData]);

    const handleSelectOption = useCallback((tagData) => {
        const newSelectedTags = multiple ? [...selectedTags, tagData.id] : tagData.id;
        setSelectedTags(newSelectedTags);

        if (onChange) {
            onChange(newSelectedTags, tagData, false);
        }
    }, [multiple, selectedTags, onChange]);

    const handleDeselectOption = useCallback((tagData) => {
        const newSelectedTags = multiple ? selectedTags.filter(id => id !== tagData.id) : null;
        setSelectedTags(newSelectedTags);

        if (onChange) {
            onChange(newSelectedTags, tagData, true);
        }
    }, [selectedTags, onChange, multiple]);

    const onSelectTag = useCallback((newSelectedTags, tagData, isSelected) => {
        setSelectedTags(newSelectedTags);
        if (onChange) {
            onChange(newSelectedTags, tagData, isSelected);
        }
    }, [onChange]);

    const selectedTagsData = useMemo(() => Array.isArray(selectedTags) ? options.filter(tag => selectedTags.includes(tag.id)) : options.find(tag => tag.id === selectedTags), [options, selectedTags]);

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            if (multiple ? Array.isArray(defaultValue) : !!defaultValue) {
                setSelectedTags(defaultValue);
            }
        }

        if (multiple ? Array.isArray(value) : !!value) {
            setSelectedTags(value);
        }
    }, [defaultValue, value, multiple]);

    if (hideEmpty && !filteredOptions.length) {
        return <></>;
    }

    return (
        <div className={`bimmatch-tag-selector tag-selector-type-${type} ${className || ""} ${highlighted ? "highlighted" : ""}`}>
            {label && <div className='tag-selector-title truncate-text' title={label}>{label}</div>}

            {(type === "select" || type === "multiselect") ? (
                <div className='tags-list'>
                    <SelectType
                        totalSelectedData={multiple ? selectedTagsData : (selectedTagsData ? [selectedTagsData] : [])}
                        searchData={options}
                        filterData={filterData}
                        searchInput={searchInput}
                        onSelectOption={handleSelectOption}
                        onDeselectOption={handleDeselectOption}
                    />
                </div>
            ) : (
                <FormFields
                    className={`tag-selector-field-${id}`}
                    hideLabel
                    placeholder={placeholder}
                    type={type}
                    options={options}
                    value={value}
                    onChange={onSelectTag}
                    {...restProps}
                />
            )}
        </div>
    )
}

export default TagSelector
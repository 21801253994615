import { useAtom, useSetAtom } from "jotai";
import { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import {
  organizationState,
  permissionsState,
  planDetailsState,
  propertyValueOptionsState,
  userFeaturesPermissionsState,
  userState,
} from "../atoms";
import { client } from "../common/apollo/ApolloClient";
import {
  GET_ORGANIZATION,
  GET_PROPERTY_VALUE_OPTIONS,
  GET_USER,
} from "../graphqlQueries";
import {
  getCookie,
  isPlanner,
  isVendor,
  setCookie,
  transformPropertyValueOptions,
  deepClone
} from "../utils";
import { GET_PERMISSIONS } from "../graphql/permissions";

export default function useUserOrganizationPermissionData() {
  //
  const setPermissions = useSetAtom(permissionsState);
  const [userFeaturesPermissions, setUserFeaturesPermissions] = useAtom(userFeaturesPermissionsState);

  //recoil
  const setPlanDetailsState = useSetRecoilState(planDetailsState);
  const setUserState = useSetRecoilState(userState);
  const setOrganization = useSetRecoilState(organizationState);
  const setPropertyValueOptionsState = useSetRecoilState( propertyValueOptionsState);

  const fetchUserOrgPermission = useCallback(async () => {

    try {
      //User
      const userAPI = await client.query({
        query: GET_USER,
        partialRefetch: true,
      });

      const userData = userAPI?.data?.getUser;
      const userAllOrganizations = userData?.organizations;
      const prevOrgId = getCookie("__current_organization");

      // if not getting prevOrgId then setting the first organization as default one
      const currentOrgId =  (userAllOrganizations?.length > 1) ? (prevOrgId || userAllOrganizations[0].id)  : userAllOrganizations[0].id;

      setCookie("__current_organization", currentOrgId);

      const intialApisToCall = [
        GET_ORGANIZATION,
        GET_PERMISSIONS,
        GET_PROPERTY_VALUE_OPTIONS,
      ].map((typeOfAPI) => client.query({
          query: typeOfAPI,
          partialRefetch: true,
        })
      );

      const userAndOrgData = await Promise.allSettled(intialApisToCall);

      const [
        getOrganizationData,
        getPermissionsData,
        getPropertyValueOptionsData,
      ] = userAndOrgData.map((promiseResponse) => promiseResponse?.value?.data);

      //Property Value Options
      const propertyValueOptionsData =  getPropertyValueOptionsData?.getPropertyValueOptions;

      //Org
      const organization = getOrganizationData?.getOrganization;

      // Permissions / Plan
      const permissionData = getPermissionsData?.getPermissions;
      const currentPlanDetails = permissionData.organizationPlan
      const orgPageAccess = currentPlanDetails.pageAccess

      const isPlannerOrganization = isPlanner(currentPlanDetails?.id);
      const isVendorOrganization = isVendor(currentPlanDetails?.id);

      // const roles = (userAllOrganizations || []).find((org) => org.id === organization.id)?.roles || [];

      // all permissons enabled for the user
      // const orgPermissions = permissionData?.userRoles.filter((role) => roles.includes(role.id)).map((role) => role?.permissions || []).flat();

      const pageAccess = {}
      const pageWisePermissions = deepClone({...userFeaturesPermissions.pageWisePermissions})
      
      orgPageAccess.forEach(pageAcessData => {
        // pafe permissions
        pageAccess[pageAcessData.id] = pageAcessData.value

        // feature permissions inside a page
        pageWisePermissions[pageAcessData.id] = pageAcessData.permissions.reduce((acc, perKey) => {
              acc[perKey] = true
              return acc
          }, pageWisePermissions[pageAcessData.id])

        // feature limits  
        if (pageAcessData?.limits) {
          let limits = {}
          pageAcessData.limits.forEach(limitData => {
            if (limitData.value) {
              limits[limitData.id] = limitData.maxLimit
            }
          });

          if (Object.keys(limits).length) {
            pageWisePermissions[pageAcessData.id].limits = limits
          }
        }

      })

      const finalFeaturesPermissions = {
        pageAccess: {...userFeaturesPermissions.pageAccess, ...pageAccess},
        pageWisePermissions
      };

      // saving to global states
      setPropertyValueOptionsState(transformPropertyValueOptions(propertyValueOptionsData));
      // console.log("finalFeaturesPermissions", permissionData);
      
      setUserFeaturesPermissions(finalFeaturesPermissions);
      setPermissions(permissionData);
      setPlanDetailsState(currentPlanDetails);

      setUserState(userData);
      setOrganization({
        ...organization,
        isPlanner: isPlannerOrganization,
        isVendor: isVendorOrganization,
        isPremiumUser: isPlannerOrganization || isVendorOrganization,
        isAdmin: userData.id === organization.owner
      });

      // clearing the local data that graphql is storing
      client.stop();
      await client.clearStore();
    } catch (error) {
      console.log("Error in Plan/Org/User details API", error);
    }
  }, [
    setUserState,
    setPropertyValueOptionsState,
    setPermissions,
    setPlanDetailsState,
    setOrganization,
    setUserFeaturesPermissions,
    userFeaturesPermissions,
  ]);

  return { fetchUserOrgPermission };
}
